import React from 'react';
import { ResultList as HeadlessResultList, SearchEngine } from '@coveo/headless';
import { FunctionComponent, useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { ResultLink } from '../resultlink';
interface ResultListProps {
  controller?: HeadlessResultList;
  hostName?: string;
  engine: SearchEngine;
  result?: any;
}

export const ConsultantResult: FunctionComponent<ResultListProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller?.state);

  useEffect(() => controller?.subscribe(() => setState(controller?.state)), [controller]);

  function getSpecialties(props: any) {
    var Specialties = props.result.raw.consultantspecialties;
    return (
      <>
        {Specialties?.map((specialty: any, i: any) => (
          <>
            <span className="specialty" key={i}>
              {specialty}{' '}
            </span>
          </>
        ))}
      </>
    );
  }

  function getSubspecialties(props: any) {
    var Subspecialties = props.result.raw.consultantsubspecialties;
    return (
      <>
        {Subspecialties?.map((subspecialty: any, i: any) => (
          <>
            {i === 0 && (
              <span className="subspecialty" key={i}>
                {subspecialty}
              </span>
            )}
            {i > 0 && (
              <span className="subspecialty" key={i}>
                {' - '} {subspecialty}
              </span>
            )}
          </>
        ))}
      </>
    );
  }

  function getLocations(props: any) {
    var locations = props.result.raw.consultantlocations;
    return (
      <>
        {locations?.map((location: any, i: any) => (
          <>
            <p key={i}>{location}</p>
          </>
        ))}
      </>
    );
  }

  function getPhoneList(props: any) {
    var numbers = props.result.raw.consultantphonenumbers;
    return (
      <>
        {numbers?.map((num: any, i: any) => (
          <li key={i}>
            Tel:<a href={'tel:' + num}> {num}</a>
          </li>
        ))}
      </>
    );
  }

  function getLink(props: any) {
    let tempIndex = props.result.clickUri?.indexOf('_resources');
    if (tempIndex === -1) {
      tempIndex = props.result.clickUri?.indexOf('data');
    }
    return props.result.clickUri?.slice(props.result.clickUri?.indexOf('consultants'), tempIndex);
  }

  return (
    <>
      {/* Flex container for photo and details container */}
      <Grid container className="consultant-profile-wrapper">
        <Grid item xs={12} sm={3}>
          <div className="image-container">
            <img
              src={
                props.result.raw.consultantimage
                  ? props.result.raw.consultantimage
                  : '/-/media/project/upmc/ireland/images/consultants/stu/upmc-ireland-consultant.png'
              }
              alt={props.result.raw.displayz32xname}
            />
          </div>
        </Grid>
        {/* Container for specialty, location, and contact */}
        <Grid item xs={12} sm={9} className="details-wrapper">
          {/* Title & Full Name */}
          <h2 className="display-name">{props.result.raw.displayz32xname}</h2>
          {/* Specialty */}
          <h3 className="specialty-heading">{getSpecialties(props)}</h3>
          <span className="subspecialty-heading">{getSubspecialties(props)}</span>
          <Grid container className="contact-location-container">
            <Grid item xs={12} md={6}>
              {/* Contacts */}
              <h6 className="contact-heading">Referral Contact</h6>
              <ul className="phone-list">{getPhoneList(props)}</ul>
            </Grid>
            <Grid item xs={12} md={6}>
              {/* Location */}
              <h6 className="locations-heading">Locations</h6>
              <span className="consultant-location">{getLocations(props)}</span>
            </Grid>
          </Grid>
          <div className="view-profile">
            <ResultLink
              result={props.result}
              hostName={props.hostName}
              useUrlAsLinkText={false}
              engine={props.engine}
              getUrl={() => getLink(props)}
            >
              <Button color="secondary" variant="contained">
                View Profile
              </Button>
            </ResultLink>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
